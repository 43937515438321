import React from 'react';
import { Alert, Button, Grid, Snackbar, Typography } from "@mui/material";
import { FormControl, Input, TextField, Hidden } from "@mui/material";
import { redirect  } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import emailjs from '@emailjs/browser';



/**
 * 
 * 
*/
export class RegisterConfirmation extends React.Component 
{
     constructor(props) {

          super(props);

          this.state = { 
               snackOpen: false,
               snackSeverity: 'warning',
               snackMessage: 'Loading...'
          };
     }

     closeSnack = () => {
          this.setState({ snackOpen: false });
     }

     handleButtonClick = () => {
          this.props.history.push('https://buy.stripe.com/aEU8Atebeg5Lg5qeUU');
          this.props.navigate('/new-path');
          //return redirect('https://buy.stripe.com/aEU8Atebeg5Lg5qeUU');
     };


     /*
      * Email Submission mechanics and error handling. 
      * Submission aided with emailJs.
      * 
     */ 
     handleSubmit(e) {
          e.preventDefault(); 
          

          /*
          * email submission setup via emailJs
          */ 
          emailjs.send(
               'service_u2aaddx', 
               'template_xn6lzy8', 
               this.props.values, 
               '-N2oCE-_d5Ayarplr'
          ).then(
               (result) => { 
                    this.setState({ 
                         snackOpen: true, 
                         snackSeverity: 'success',
                         snackMessage: 'Your premium hosting solution is being created. HostBot will be in touch shortly.' 
                    }) 
                    //this.props.router.navigate('/success');  // Redirect on success
                    //console.log(this.props.values);
                    //return redirect('https://buy.stripe.com/aEU8Atebeg5Lg5qeUU');
                    
               }, 
               (error)  => { 
                    this.setState({ 
                         snackOpen: true, 
                         snackSeverity: 'error', 
                         snackMessage: 'An error has occured. A technical ticket has been generated.' 
                    }) 
                    console.log(this.props.values);
                    return redirect('https://buy.stripe.com/aEU8Atebeg5Lg5qeUU');
               }
          )
     }

     render() 
     {
          const {
               values: { 
                    teirPrice,
                    domainNew, 
                    registeredDomain, 
                    emailServiceSetup, 
                    numberOfEmails, 
                    webhostServiceSetup, 
                    webhostBillingSetup,
                    firstName, 
                    lastName, 
                    email, 
                    contactPhone,
                    webHostPackage,
                    annualCheckoutUrl,
                    monthlyCheckoutUrl
               },
               back
          } = this.props;

          
          return(
               <React.Fragment>

                    <Snackbar 
                         open={ this.state.snackOpen } 
                         autoHideDuration={ 3500 } 
                         TransitionComponent="TransitionUp"
                         anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                         onClose={ this.closeSnack }
                         >
                         <Alert severity={ this.state.snackSeverity } variant="filled" fullWidth>
                              { this.state.snackMessage }
                              <IconButton size="small" color="inherit" onClick={ this.closeSnack }>
                                   <CloseIcon fontSize="small" />
                              </IconButton>
                         </Alert>
                    </Snackbar>

                    <form onSubmit={ this.handleSubmit.bind(this) } Validate>
                         <Grid container rowSpacing={3} columnSpacing={{ xs: 3 }}>
                              <Grid item xs={12}>
                                   <Typography variant="body1" display="h2" color="#7758b0" sx={{mb: 0 }}>
                                        <b>Review Business Profile</b>
                                   </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                   <FormControl fullWidth>
                                        <TextField 
                                             id="firstName" 
                                             name="firstName" 
                                             label="First Name" 
                                             defaultValue={firstName} 
                                             InputLabelProps={{ shrink: true }}
                                             size="small"
                                             variant="filled" 
                                             color="secondary"
                                             required
                                             disabled 
                                        />
                                   </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                                   <FormControl fullWidth>
                                        <TextField 
                                             id="lastName" 
                                             name="lastName" 
                                             label="Last Name" 
                                             defaultValue={lastName} 
                                             InputLabelProps={{ shrink: true }}
                                             size="small"
                                             variant="filled" 
                                             required
                                             disabled 
                                        />
                                   </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                                   <FormControl fullWidth>
                                        <TextField 
                                             id="email" 
                                             name="email" 
                                             label="Email Address" 
                                             defaultValue={email} 
                                             InputLabelProps={{ shrink: true }}
                                             size="small"
                                             variant="filled" 
                                             required
                                             disabled 
                                        />
                                   </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                                   <FormControl fullWidth>
                                        <TextField 
                                             id="contactPhone" 
                                             name="contactPhone" 
                                             label="Phone Number" 
                                             defaultValue={contactPhone} 
                                             InputLabelProps={{ shrink: true }}
                                             size="small"
                                             variant="filled" 
                                             required
                                             disabled 
                                        />
                                   </FormControl>
                                   <Input 
                                        id="teirPrice" 
                                        type="hidden"
                                        name="teirPrice" 
                                        defaultValue={teirPrice} 
                                        disabled 
                                   />
                              </Grid>
                         </Grid>
                         <Grid container rowSpacing={3} columnSpacing={{ xs: 3 }} sx={{ mt: 2, mb: 3 }}>
                              <Grid item xs={12}>
                                   <Typography variant="body1" display="h2" color="#7758b0" sx={{mb: 0 }}>
                                        <b>Review Your Domain &amp; Hosting Setup</b>
                                   </Typography>  
                              </Grid>
                              <Grid item xs={6}>
                                   <FormControl fullWidth>
                                        <TextField 
                                             id="domainNew" 
                                             name="domainNew" 
                                             label="Register New Domain" 
                                             defaultValue={ domainNew ? domainNew : 'Not Applicable' }
                                             variant="filled" 
                                             size="small"
                                             required
                                             disabled 
                                        />
                                   </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                                   <FormControl fullWidth>
                                        <TextField 
                                             id="registeredDomain" 
                                             name="registeredDomain" 
                                             label="My Existing Domain" 
                                             defaultValue={ registeredDomain ? registeredDomain : 'Not Applicable' }
                                             size="small"
                                             variant="filled" 
                                             required
                                             disabled 
                                        />
                                   </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                                   <FormControl fullWidth>
                                        <TextField 
                                             id="emailServiceSetup" 
                                             name="emailServiceSetup" 
                                             label="My Email Setup" 
                                             defaultValue={emailServiceSetup} 
                                             size="small"
                                             variant="filled" 
                                             required
                                             disabled 
                                        />
                                   </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                                   <FormControl fullWidth>
                                        <TextField 
                                             id="numberOfEmails" 
                                             name="numberOfEmails" 
                                             label="Number of Emails"
                                             defaultValue={numberOfEmails} 
                                             size="small"
                                             variant="filled" 
                                             required
                                             disabled 
                                        />
                                   </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                                   <FormControl fullWidth>
                                        <TextField 
                                             id="webhostServiceSetup" 
                                             name="webhostServiceSetup" 
                                             label="Web Hosting Setup" 
                                             defaultValue={ webhostServiceSetup ? webhostServiceSetup : 'Not Applicable' }
                                             size="small"
                                             variant="filled" 
                                             required 
                                             disabled 
                                        />
                                   </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                                   <FormControl fullWidth>
                                        <TextField 
                                             id="webhostBillingSetup" 
                                             name="webhostBillingSetup" 
                                             label="Billing Cycle" 
                                             defaultValue={ webhostBillingSetup }
                                             size="small"
                                             variant="filled" 
                                             required 
                                             disabled 
                                        />
                                   </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                                   <FormControl fullWidth>
                                        <Hidden 
                                             id="webHostPackage" 
                                             name="webHostPackage" 
                                             label="Web Package" 
                                             defaultValue={ webHostPackage }
                                             size="small"
                                             variant="filled" 
                                             required 
                                             disabled 
                                        />
                                   </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                              <FormControl fullWidth>
                                        <Hidden 
                                             id="monthlyCheckoutUrl" 
                                             name="monthlyCheckoutUrl" 
                                             defaultValue={ monthlyCheckoutUrl }
                                             size="small"
                                             variant="filled" 
                                             required 
                                             disabled 
                                        />
                                        <Hidden 
                                             id="annualCheckoutUrl" 
                                             name="annualCheckoutUrl" 
                                             defaultValue={ annualCheckoutUrl }
                                             size="small"
                                             variant="filled" 
                                             required 
                                             disabled 
                                        />
                                   </FormControl>
                              </Grid>
                         </Grid>
                         <Grid container rowSpacing={1} columnSpacing={{ xs: 3 }}>
                              <Grid item xs={6}>

                                   <Button 
                                        onClick={back} 
                                        variant="contained" 
                                        color="teritary" 
                                        className="nextMenu">
                                        Back
                                   </Button>
                              </Grid>
                              <Grid item xs={6}>
                                   <Button 
                                        type="submit" 
                                        variant="contained" 
                                        color="success" 
                                        className="nextMenu floatRight">
                                        Submit Request
                                   </Button>
                              </Grid>
                         </Grid>
                    </form>
               </React.Fragment>
          );
     }
}
export default RegisterConfirmation;