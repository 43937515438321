import * as React from 'react';
import { Box, Card, CardContent, Divider, Grid, Stack, Typography } from '@mui/material';
import DialogHostSelection from "./dialog/DialogHostSelection";



/*
* Each priced teir and its given content
*
*/
export function PriceTeir(props) 
{
     /*
      * Loop Teir benefits array
      *
     */
     //const priceTeirInfo = this.props.teirInformation;
     //const benefitsList  = Object.values(priceTeirInfo.benefits);
     //this.props.registeredDomain
     const benefitsList = props.teirInformation.benefits;
     
     /*
     const tierBenefits  = benefitsList.map((item, index) => {
          return (
               <li key={index}>{item}</li>
          );
     });
     */
    //console.log(benefitsList);

     /*
      * Return View
      *
     */
     return(
          <React.Fragment>
               <Grid item md={6} lg={4} alignItems="stretch">
                    <Card sx={{ fontSize: '0.9em', backgroundColor: '#221629', borderTop: '7px solid #4c346b' }}>
                         <CardContent>
                              <Typography variant="h3" component="paragraph" color="#FFFFFF" >
                                   <strong>{ props.teirInformation.name }</strong>
                              </Typography>
                              <Typography  classes="description" color="#FFFFFF" sx={{fontSize: '1rem'}} gutterBottom>
                                   { props.teirInformation.description }
                              </Typography>

                              <Stack 
                                   sx={{ backgroundColor: '#462f63', borderRadius: '6px' }} 
                                   divider={<Divider orientation="vertical" flexItem />}
                                   spacing={2} my={2} pt={1}  pb={1}
                                   direction="row">
                                   <Box sx={{ textAlign: 'center', width: '100%' }}>
                                        <Typography id="singleMonthlyPrice" color="#FFFFFF" sx={{ fontSize: '2.5em'  }}>
                                             ${ props.teirInformation.monthlyCost }
                                             <Typography variant="caption" color="#FFFFFF" display="inline-block" gutterBottom>
                                             &nbsp; / MONTH
                                             </Typography>
                                        </Typography>
                                 
                                   </Box>
                              </Stack>

                              <Box sx={{ p: 2, mt: 2, backgroundColor: '#291a31', color: '#FFFFFF', borderRadius: '6px' }}>
                                   {benefitsList.map((item, index) => {
                                             return <li key={index}>{item}</li>;
                                        })
                                   }
                              </Box>
                         </CardContent>

                         <DialogHostSelection 
                              selectionPackage = { props.teirInformation.name } 
                              selectionPrice      = { props.teirInformation.monthlyCost} 
                              selectionAnnualUrl  = { props.teirInformation.annualCheckoutUrl }
                              selectionMonthlyUrl = { props.teirInformation.monthlyCheckoutUrl }
                              selectionSteps      = { props.teirInformation.teirData }
                         />

                    </Card>
               </Grid>
          </React.Fragment>
     )
}
export default PriceTeir;