import React, { useEffect } from 'react';
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { Container, Button, Grid, Stack, Typography } from '@mui/material';
import { animateContainer } from "../assets/Animations";
import DialogForm from "../components/dialog/DialogForm";
import SendIcon from '@mui/icons-material/Send';



/*
 * Framer Motion visibility transitions (intersection observer)
 *
*/
export default function FrequentQuestions(props) 
{
     const { ref, inView } = useInView({ threshold: [0.20] });
     const controls = useAnimation();


     /*
      * Framer Motion visibility transitions (intersection observer)
      *
     */
     useEffect(() => {
          if(inView) {
            controls.start('visible');
          }
          if(!inView) {
            controls.start('hidden');
          }
     }, [controls, inView]);


     const handlePricingClick = (e) => {
          props.fullPageSlide.moveTo(2);
     }


     return(
          <React.Fragment>
               <motion.div ref={ref} variants={animateContainer} initial="hidden" animate={controls}>
                    <Container className="PricingTable" sx={{ padding: '2em 1.5em' }}>

                         <Typography variant="h1" component="h1"  color="secondary" sx={{ marginBottom: '0.5em' }}>
                              Frequently Asked Questions
                         </Typography>

                         <Grid container spacing={10} sx={{ color: '#FFF', paddingTop: '1em' }}>
                              <Grid item lg={4}>
                                   <p>If you can't find the answer you're looking for in our FAQs, 
                                        don't hesitate to contact our dedicated customer support team. 
                                        They are available to assist you with any questions or 
                                        concerns you may have.
                                   </p>
                                   <Stack spacing={2} mt={4} direction="row">
                                        <Button 
                                             variant="outlined" 
                                             onClick={ handlePricingClick }>
                                             Pricing
                                        </Button>
                                        <DialogForm  
                                             triggerVariant="text" 
                                             triggerColor="secondary" 
                                             triggerMessage="Need Hosting Support?" 
                                             triggerIcon={<SendIcon />}
                                        />
                                   </Stack>
                              </Grid>
                              <Grid item lg={8} sx={{ color: '#FFF' }}>
                                  
                                   <h3 class="mt-0 mb-0">What services do you offer?</h3>
                                   <p>We are a premium hosting company that provides comprehensive services including web hosting, WordPress support, website maintenance, and access to premium Elementor features.</p>

                                   <h3 class="mb-0">What are the payment options for your hosting plans?</h3>
                                   <p>We offer both monthly and annual payment options for all our hosting plans. By choosing the annual payment option at checkout, you can enjoy a 20% discount on the total cost. The discount is automatically applied when you select the annual plan, making it a cost-effective choice for long-term hosting. You can cancel your subscription anytime with a 20-day notice of your next billing cycle.</p>

                                   <h3 class="mb-0">Is there a trial period available?</h3>
                                   <p>Yes, we offer a 14-day trial period for all new users. This allows you to experience our premium hosting services and decide if they are the right fit for your needs.</p>

                                   <h3 class="mb-0">What premium templates are available?</h3>
                                   <p>For Growth and Enterprise Plan users, we offer access to premium templates. You can browse any template on Envato Elements and let us know which one you want. We will set it up for you.</p>

                                   <h3 class="mb-0">Can I request custom development services?</h3>
                                   <p>Absolutely! We offer custom development services at a rate of $150 per hour. Additionally, if you commit to a custom development project over 10 hours, you will receive a 10% discount on the total cost.</p>

                                   <h3 class="mb-0"> Can I install my own plugins?</h3>
                                   <p>To maintain optimal performance and security, we do not allow the installation of external plugins. However, we provide a robust set of pre-approved plugins that cover a wide range of functionalities.</p>

                                   <h3 class="mb-0">What security measures are in place?</h3>
                                   <p>All our plans come with essential security features, including free SSL certificates and daily backups. Advanced security features and malware scanning are available starting from the Growth Plan. Enterprise Plans include enhanced security protocols.</p>

                                   <h3 class="mb-0">Are e-commerce features available?</h3>
                                   <p>E-commerce support, including WooCommerce integration, is available on the Growth Plan and above. This includes advanced SEO tools and support for online stores.</p>

                                   <h3 class="mb-0"> Is a domain name included with the hosting plan?</h3>
                                   <p>We do not offer domain name registration directly. However, we can help you set up your domain under your own account and name.</p>

                                   <h3 class="mb-0">Can I upgrade my plan at any time?</h3>
                                   <p>Yes, you can upgrade your plan at any time. The upgrade will be prorated based on the remaining time in your current billing cycle.</p>

                                   <h3 class="mb-0">How do I cancel my subscription?</h3>
                                   <p>You can cancel your subscription at any time with a 30-day notice. Simply contact our support team, and they will assist you with the cancellation process.</p>

                                   <h3 class="mb-0">What is your refund and cancellation policy?</h3>
                                   <p>We offer a 14-day trial period for all new users. If you decide to cancel within this period, you will not be charged. For all plans, a 20-day cancellation notice is required. Simply contact our support team, and they will assist you with the cancellation process.</p>

                                   <h3 class="mb-0">What if I need more storage or bandwidth?</h3>
                                   <p>If you require additional storage or bandwidth beyond what is included in your plan, please contact our support team. We can customize a plan to meet your specific needs, especially for Enterprise Plan users.</p>

                                   <h3 class="mb-0">What makes your service different from other hosting providers?</h3>
                                   <p>As a premium hosting company, we offer comprehensive services that include hosting, WordPress support, website maintenance, and access to premium Elementor features. Our focus on security, performance, and customer satisfaction sets us apart. Additionally, our unique offerings such as exclusive tutorials, webinars, and loyalty discounts provide added value to our customers.</p>
                              </Grid>
                         </Grid>

                    </Container>
               </motion.div>
          </React.Fragment>
     );
}