import React from "react";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import MailLockIcon from '@mui/icons-material/MailLock';
import QuizIcon from '@mui/icons-material/Quiz';



/**
 * Render Top Level Menu
 * 
*/
export default function TopMenu(props) {
     return(
          <React.Fragment >
               <Container fixed>
                    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                    <Button
                              id="fade-button"
                              variant="text"
                              color="secondary"
                              startIcon={<QuizIcon />}
                              href="#faq">
                              FAQ
                         </Button>
                         <Button
                              id="fade-button"
                              variant="text"
                              color="secondary"
                              startIcon={<MailLockIcon />}
                              aria-haspopup="true"
                              href="mailto:support@hostbot.ca"
                              disableElevation>
                                   Need Assistance?
                         </Button>
                    </Stack>
               </Container>
          </React.Fragment> 
     )
}