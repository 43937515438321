import React, { Component } from "react";
import { Button, Typography, Grid } from '@mui/material';
import { TextField, FormControl, InputAdornment } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';



export class RegisterDomain extends Component 
{
     render() 
     {
          const { values, handleChange, continues } = this.props;
          
          return(
               <React.Fragment>
                    <Grid container rowSpacing={3} columnSpacing={{ xs: 3 }}>
                         <Grid item xs={12}>
                              <Typography component="h3" variant="h6" color="#7758b0">
                                   About Your Domain
                              </Typography>
                              <Typography variant="body1" gutterBottom>
                                   Do you currently have a domain name?  Make a note of it below! 
                                   If you require assistance registering a domain -- allow us to help, 
                                   while ensuring you retain full ownership.
                              </Typography>
                         </Grid>
                         <Grid item xs={6}>
                              <Typography variant="body1" display="h2" color="#7758b0" sx={{mb: 2 }}>
                                   <b>Host an Existing Domain</b>
                              </Typography>
                              <FormControl fullWidth>
                                   <TextField 
                                        variant="outlined"
                                        size="small"
                                        id="registeredDomain" 
                                        name="registeredDomain"
                                        placeholder="yourdomain.com" 
                                        label="My Domain Name"
                                        helperText="Note the domain you would like to host"
                                        defaultValue={values.registeredDomain}
                                        onChange={handleChange("registeredDomain")}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                             endAdornment: (
                                                  <InputAdornment position="end">
                                                       <LanguageIcon />
                                                  </InputAdornment>
                                             ),
                                        }}
                                   />
                              </FormControl>
                         </Grid>
                         <Grid item xs={6}>
                              <Typography variant="body1" display="h2" color="#7758b0" sx={{mb: 2 }} >
                                   <b>Looking to Register a New Domain?</b>
                              </Typography>
                              <FormControl fullWidth>
                                   <TextField 
                                        variant="outlined" 
                                        size="small" 
                                        id="domainNew"
                                        name="domainNew" 
                                        label="New Domain Name"
                                        helperText="Note the domain you're looking to register"
                                        placeholder="yourdomain.com"
                                        defaultValue={values.domainNew}
                                        onChange={handleChange("domainNew")}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                             endAdornment: (
                                                  <InputAdornment position="end">
                                                       <LanguageIcon />
                                                  </InputAdornment>
                                             ),
                                        }}
                                   />
                              </FormControl>
                         </Grid>
                         <Grid item xs={12}>
                              <Button 
                                   onClick={continues} 
                                   variant="contained" 
                                   color="teritary" 
                                   className="nextMenu floatRight">
                                   Continue
                              </Button>
                         </Grid>
                    </Grid>
               </React.Fragment>
          );
     }
}
export default RegisterDomain;