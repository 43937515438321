import React, { Component } from "react";
import { Button, Typography, Grid } from '@mui/material';
import { TextField, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';



export class RegisterWebHosting extends Component 
{
     render() 
     {
          const { values, handleChange, back, continues } = this.props;
          
          return (
               <React.Fragment>
                    <Grid container rowSpacing={3} columnSpacing={{ xs: 3 }}>
                         <Grid item xs={12}>
                              <Typography component="h3" variant="h6" color="#7758b0">
                                   About Your Web Hosting
                              </Typography>
                              <Typography variant="body1" gutterBottom>
                                   Outline your web host setup below, and how we can create a plan 
                                   to grow your online business. Web hostin is an agile service, and can be setup to grow 
                                   or transition your business easily.
                              </Typography>
                         </Grid>
                         <Grid item xs={6}>
                              <Typography variant="body1" display="h2" color="#7758b0"  sx={{mb: 2}}>
                                   <b>How is Your Website Currently Hosted?</b>
                              </Typography>
                              <FormControl size="small" required fullWidth>
                                   <InputLabel id="webhostServiceSetup">
                                        Web Host Service
                                   </InputLabel>
                                   <Select 
                                        id="webhostServiceSetup"
                                        labelId="webhostServiceSetup" 
                                        label="Web Host Service"
                                        defaultValue={values.webhostServiceSetup}
                                        onChange={handleChange("webhostServiceSetup")}
                                        >
                                        <MenuItem value="No Hosting Setup Presently">No Hosting Presently</MenuItem>
                                        <MenuItem value="Standard Hosting">Standard Hosting (GoDaddy, WHC)</MenuItem>
                                        <MenuItem value="WIX Hosting">WIX Hosting</MenuItem>
                                        <MenuItem value="Square Space">Square Space</MenuItem>
                                        <MenuItem value="WordPress Hosting">WordPress Hosting (FlyWheel, wpEngine)</MenuItem>
                                        <MenuItem value="Dedicated Server">Dedicated Server (Private IP)</MenuItem>
                                        <MenuItem value="yaa... not sure.">Unsure, really</MenuItem>
                                   </Select>
                              </FormControl>
                         </Grid>
                         <Grid item xs={6}>
                              <Typography variant="body1" display="h2" color="#7758b0" sx={{mb: 2 }}>
                                   <b>Which billing cycle do you prfer?</b>
                              </Typography>
                              <FormControl size="small" required fullWidth>
                              <InputLabel id="webhostBillingSetup">
                                        Billing Cycle
                                   </InputLabel>
                              <Select 
                                        id="webhostBillingSetup"
                                        labelId="webhostBillingSetup" 
                                        label="Billing Cycle"
                                        defaultValue={values.webhostBillingSetup}
                                        onChange={handleChange("webhostBillingSetup")}
                                        >
                                        <MenuItem value="Monthly Billing">Monthly</MenuItem>
                                        <MenuItem value="Annual Billing">Annual</MenuItem>
                                   </Select>
                                   
                              </FormControl>
                         </Grid>
                         <Grid item xs={6}>
                              <Button 
                                   onClick={back} 
                                   variant="contained" 
                                   color="teritary" 
                                   className="nextMenu">
                                   Back
                              </Button>
                         </Grid>
                         <Grid item xs={6}>
                              <Button 
                                   onClick={continues} 
                                   variant="contained" 
                                   color="teritary" 
                                   className="nextMenu floatRight">
                                   Continue
                              </Button>
                         </Grid>
                    </Grid>
               </React.Fragment>
          );
     }
}
export default RegisterWebHosting;