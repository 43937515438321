import React from 'react';
import { Button, CardActions, Grid, Typography } from '@mui/material';
import { Dialog, DialogContent } from '@mui/material';
import HostPackageStepper from "../HostPackageStepper";


/*
 * Dialog Window Outlining the Hosting Stepper
 *
*/
export default function DialogHostSelection(props) 
{     
     const [open, setOpen] = React.useState(false);
     const [setMaxWidth] = React.useState('lg');
     const dialogClickOpen = () => { setOpen(true); };
     const dialogClose = () => { setOpen(false); };


     return(
          <React.Fragment>
               <CardActions>
                    <Button 
                         onClick={ dialogClickOpen }
                         variant="contained"  
                         sx={{ backgroundColor: '#462f63', color: '#FFF', borderRadius: '6px', width: '100%' }}
                         >
                         Get Started
                    </Button>
               </CardActions>
               <Dialog 
                    open={open} 
                    onClose={ dialogClose } 
                    transitionDuration={{ enter: 1200, exit: 1200 }} 
                    maxWidth={setMaxWidth}
                    >
                    <Grid container>
                         <Grid item md={7} sx={{backgroundColor: '#f5eff7'}}>
                              <DialogContent>
                                   <Typography variant="h5" component="h2" mb={4}>
                                        { props.selectionPackage }&nbsp;
                                        <Typography variant="span" component="span" color="primary">
                                             (${ props.selectionPrice } Monthly)
                                        </Typography>
                                   </Typography>

                                   <HostPackageStepper 
                                        priceSelection={ props.selectionPrice } 
                                        annualCheckoutUrl={ props.selectionAnnualUrl }
                                        monthlyCheckoutUrl={ props.selectionMonthlyUrl }
                                        selectionPackage={ props.selectionPackage }
                                   />

                              </DialogContent>
                         </Grid>
                         <Grid item md={5} sx={{backgroundColor: '#4b335a', color: '#FFF'}}>
                              <img src="images/fillApplication.gif" alt="Application Time" width="100%" sx={{ paddingTop: '1em' }} />
                         </Grid>
                    </Grid>
               </Dialog>
          </React.Fragment>
     );
}