import React, { Component } from "react";
import { Button, Typography, Grid } from '@mui/material';
import {  
     TextField, 
     FormControl, 
     FormHelperText,
     InputLabel, 
     MenuItem, 
     Select 
} from '@mui/material';



export class RegisterEmailHosting extends Component 
{
     render() 
     {
          const { 
               values, 
               handleChange, 
               back,
               continues
          } = this.props;
          
          return (
               <React.Fragment>
                    <Grid container rowSpacing={3} columnSpacing={{ xs: 3 }}>
                         <Grid item xs={12}>
                              <Typography component="h3" variant="h6" color="#7758b0">
                                   About Your Email Setup
                              </Typography>
                              <Typography variant="body1" gutterBottom>
                              We don't offer email hosting, but can recommend options for you based on your specific needs. 
                              </Typography>
                         </Grid>
                         <Grid item xs={6}>
                              <Typography variant="body1" display="h2" color="#7758b0" sx={{mb: 2 }}>
                                   <b>How is Your Email  Managed?</b>
                              </Typography>
                              <FormControl size="small" required fullWidth>
                                   <InputLabel id="emailServiceSetup">
                                        Email Host Setup
                                   </InputLabel>
                                   <Select 
                                        id="emailServiceSetup"
                                        labelId="emailServiceSetup" 
                                        label="Email Host Setup"
                                        defaultValue={values.emailServiceSetup}
                                        onChange={handleChange("emailServiceSetup")}
                                        >
                                        <MenuItem value="No Active Email">No Active Email</MenuItem>
                                        <MenuItem value="cPanel Email">cPanel Email</MenuItem>
                                        <MenuItem value="GSuite">GSuite Cloud</MenuItem>
                                        <MenuItem value="Microsoft Cloud">Microsoft Cloud</MenuItem>
                                        <MenuItem value="Email Unsure">Unsure</MenuItem>
                                   </Select>
                                   <FormHelperText>
                                        What best describes your current email scenario
                                   </FormHelperText>
                              </FormControl>
                         </Grid>
                         <Grid item xs={6}>
                              <Typography variant="body1" display="h2" color="#7758b0" sx={{mb: 2 }}>
                                   <b>How Many Email Accounts?</b>
                              </Typography>
                              <FormControl fullWidth>
                                   <TextField 
                                        id="numberOfEmails"
                                        name="numberOfEmails" 
                                        variant="outlined" 
                                        size="small" 
                                        placeholder="1"
                                        label="Number of Emails"
                                        helperText="List how many email accounts your business needs"
                                        InputLabelProps={{ shrink: true }}
                                        defaultValue={values.numberOfEmails}
                                        onChange={handleChange("numberOfEmails")}
                                   />
                              </FormControl>
                         </Grid>
                         <Grid item xs={6}>
                              <Button 
                                   onClick={back} 
                                   variant="contained" 
                                   color="teritary" 
                                   className="nextMenu">
                                   Back
                              </Button>
                         </Grid>
                         <Grid item xs={6}>
                              <Button 
                                   onClick={continues} 
                                   variant="contained" 
                                   color="teritary" 
                                   className="nextMenu floatRight">
                                   Continue
                              </Button>
                         </Grid>
                    </Grid>
               </React.Fragment>
          );
     }
}
export default RegisterEmailHosting;