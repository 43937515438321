import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import App from './App';

import '@fontsource/roboto/300.css';
import '@fontsource/abel/400.css';
import "@fontsource/alice/400.css";


/**
 * Theme Design Configurations
 * 
 * @returns 
 * 
 */
 const setDarkTheme = () => ({
     mode: 'dark',
     palette: {
          primary: {
               main: "#bb82de"
          },
          secondary: {
               main: "#b798ca"
          },
          teritary: {
               main: "#563a67",
               contrastText: '#fff',
          },
          darkPurple: {
               main: "#7758b0"
          },
          contrast: {
               main: "#FFFFFF"
          }
     },
     typography: {
          fontFamily: "Roboto",
          h1: {
               fontSize: "3rem",
               color: "#11abec"
          },
          h2: {
               fontSize: "1.6rem",
               color: "#ffffff"
          },
          h3: {
               fontSize: "1.4rem",
               color: "#11abec",
               lineHeight: "2em"
             
          }
     },
     shape: {
          borderRadius: 3,  
     },
     components: {
          MuiContainer: {
            styleOverrides: {
              root: {
                '@media (min-width: 1200px)': {
                  maxWidth: '1480px', // Change this to your desired width
                },
              },
            },
          },
     }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
const designSettings = createTheme(setDarkTheme());


root.render(
     <React.StrictMode>
          <ThemeProvider theme={designSettings}>
               <CssBaseline />
               <App />
          </ThemeProvider>
     </React.StrictMode>
);
