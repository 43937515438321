import React from 'react';
import { motion } from "framer-motion"
import { Helmet } from "react-helmet";
import FullPageView from "./layout/FullPageView";
import TopMenu from './components/TopMenu';
import "./styles.css"


export default function App() 
{
     const variants = {
          hidden: { opacity: 0},
          enter:  { opacity: 1 }
     }

     return(
          <React.Fragment>
               <Helmet>
                    <title>HostBot: Host Management, Consulting &amp; Tech Support Services</title>
                    <meta property="og:title" content="HostBot: Host Management, Consulting & Tech Support Services" />
                    <meta property="og:description" content="HostBot: Host Management, Consulting & Tech Support Services" />
               </Helmet>
               <motion.div key="modal" variants={variants} initial="hidden" animate="enter" exit="hidden" >
                    <main>
                         <TopMenu />
                         <FullPageView />
                    </main>
               </motion.div>
          </React.Fragment>
     );
}