import React from 'react';
import Grid from '@mui/material/Grid';
import Logo from '../components/Logo';
import ReactFullpage from "@fullpage/react-fullpage";
import SlideIntroduction from "./SlideIntroduction";
import SlidePricing from "./SlidePricing";
import FrequentQuestions from "./FrequentQuestions";



class FullPageView extends React.Component 
{
     constructor(props) {
          super(props);
     }
     
     afterRender() {
          //setTimeout(function(fullpageApi) {
          //console.log(fullpageApi);
          //     fullpageApi.setAllowScrolling(false);
          //}, 500);
     }

     render() {
          return(
               <ReactFullpage
                    licenseKey={"1ARKK-LGH4H-Y9IMJ-26PNK-ZMGXM"}
                    scrollOverflow={ true }
                    anchors={['intro', 'pricing', 'faq']}
                    sectionsColor={["#27192f", "#27192f", "#27192f"]}
                    scrollingSpeed="1500"
                    controlArrows={false}
                    afterRender={this.afterRender.bind(this)}
                    onLeave={this.afterRender.bind(this)}
                    credits={ false }
                    render={({ state, fullpageApi }) => {
                         return (    
                              <ReactFullpage.Wrapper id="fullpage-wrapper">
                                   <div className="section section1">
                                        <Grid id="hostbot-header-menu" 
                                        sx={{ position: 'absolute', left: '1.7em', top: '0', }}
                                        >
                                             <Grid item md={6}>
                                                  <Logo />
                                             </Grid>
                                             <Grid item md={6}>
                                             
                                             </Grid>
                                        </Grid> 
                                        <SlideIntroduction fullPageSlide={fullpageApi} />
                                   </div>
                                   <div className="section section2">
                                        <div className="slide">
                                             <SlidePricing fullPageSlide={fullpageApi} />
                                        </div>
                                   </div>
                                   <div className="section section3">
                                        <div className="slide">
                                             <FrequentQuestions fullPageSlide={fullpageApi} />
                                        </div>
                                   </div>
                              </ReactFullpage.Wrapper>
                         );
                    }}
               />
          )
     }
}
export default FullPageView;