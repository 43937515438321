

export const animateContainer = {
     hidden: { opacity: 0 },
     visible: {
          opacity: 1,
          transition: {
               duration: 2,
               stiffness: 700,
               damping: 30,
               staggerChildren: 0.3
          }
     }
}


export const fadeInRight = {
     hidden: {
          opacity: 0,
          x: -70
     },
     visible: {
          opacity: 1,
          x: 0,
          transition: {
               duration: 1.25,
               stiffness: 700,
               damping: 30
          }
     }
};