import React, { useEffect } from 'react';
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { Container, Grid, Typography } from '@mui/material';
import { teirOverview } from "../assets/DataSiteContent";
import { animateContainer } from "../assets/Animations";
import PriceTeir from "../components/PriceTeir";



/*
* Framer Motion visibility transitions (intersection observer)
*
*/
export default function SlidePricing() 
{
     const { ref, inView } = useInView({ threshold: [0.20] });
     const controls = useAnimation();


     /*
     * Framer Motion visibility transitions (intersection observer)
     *
     */
     useEffect(() => {
          if(inView) {
            controls.start('visible');
          }
          if(!inView) {
            controls.start('hidden');
          }
     }, [controls, inView]);


     /*
     * Iterate through teir package information
     *
     */
     const teirPackages = teirOverview.map((item, index) => {
          return (
               <PriceTeir teirInformation={item} key={index} />
          );
     });

     
     return(
          <React.Fragment>
               <motion.div ref={ref} variants={animateContainer} initial="hidden" animate={controls}>
                    <Container className="PricingTable" sx={{ padding: '1.5em', color: '#FFF' }}>
                         <Typography variant="h1" component="h1" color="secondary" gutterBottom>
                              Premium Hosting Plans
                         </Typography>
                         
                         <Typography variant="h2" component="h2" gutterBottom>Expand Your Online Reach with Premium Hosting Tailored to Your Business Needs
                         </Typography>     

                         <Grid container mt={1} mb={2} spacing={3} alignItems="stretch">
                              { teirPackages }
                         </Grid>
                         <p><center>
                              *Kick-Off Consultation (for minor setup support and technical advice).<br/> 
                              Annual Pricing Available! We offer 20% off when you select Annual as your billing cycle. 
                         </center></p> 
                    </Container>
               </motion.div>
          </React.Fragment>
     );
}