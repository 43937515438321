import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const BobbingSVG = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <motion.img
      src="images/littleBot.png" // Replace with the path to your PNG image
      alt="Floating PNG"
      width="100" // Adjust the width and height as needed
      height="100"
      style={{
        position: 'fixed',
        bottom: scrollY + 40, // Adjust the value to position the image where you want
        right: 20, // Adjust the right position if needed
        // Optionally, you can add more styling to control the image’s appearance
      }}
      animate={{ y: [0, 20, 0] }} // Optional: add a bobbing effect
      transition={{ duration: 3, repeat: Infinity, repeatType: 'loop' }} // Optional: bobbing settings
    />
  );
};

export default BobbingSVG;
