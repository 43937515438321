/*
* Teir package content. 
* Let work to database this and integrate with prisma.
*
*/


/**
 * 
 * Mobile optimized websites
 * Templates to fit every need
 * Sell content and memberships
 * 
 * Mobile optimized websites
 * Templates to fit every need
 * Free custom domain*
 * Advanced website analytics
 * Sell products and services
 * 3% transaction fee for physical products and services
 * Checkout on your domain
 * 
 * Mobile optimized websites
 * Templates to fit every need
 * Free custom domain*
 * Advanced website analytics
 * Sell products and services
 * 0% transaction fee for physical products and services
 * Checkout on your domain
 * Powerful merchandising
 * 
 */
/*
export const teirOverview = [
     {
          name: 'BUSINESS EMAIL PACKAGE',
          tagline: 'Professional Email Hosting',
          monthlyCost: 4.95,
          teirType: 'email',
          description: 'Start promoting your brand with every email you send with professional email addresses for your business.',
          benefits: [
               '1 Email Account', 
               'Up to 1GB',
               'Webmail & POP/IMAP Support',
               'Up to 50 outgoing emails/hour',
               'Anti-virus & Anti-Spam',
               'Free Email Migration', 
               'Hostbot Management Features'
          ]
     }, 
     {
          name: 'DIGITAL BUSINESS PACKAGE',
          tagline: 'Website & Email Hosting',
          monthlyCost: 13.95,
          teirType: 'email',
          description: 'Start growing your brand with reliable hosting tools for managing your website and emails.',
          benefits: [
               '3 Websites',
               '10GB Server Memory',
               '30 cPanel Email Accounts',
               'cPanel Access',
               'Free Website(s) Migration',
               'Free Email Migration', 
               'Hostbot Management Features'
          ]
     },
     {
          name: 'ROBUST BUSINESS PACKAGE',
          tagline: 'An Individualized Focus',
          monthlyCost: 32.95,
          teirType: 'email',
          description: 'This robust package allows organizations to manage a large network of websites under one umbrella.',
          benefits: [
               'Unlimited Websites',
               'Unlimited Email Accounts',
               'Unlimited Space & Traffic',
               'cPanel Access',
               'Dedicated IP',
               'Hostbot Management Features',
               'and much more...'
          ]
     }
];
*/


export const teirOverview = [
     {
          name: 'Starter Plan',
          tagline: 'Affordable Entry-Level Plan',
          monthlyCost: 16.00,
          monthlyCheckoutUrl: 'https://buy.stripe.com/aEU8Atebeg5Lg5qeUU',
          annualCost: 153.60,
          annualCheckoutUrl: 'https://buy.stripe.com/aEUdUNffi7zf2eA145',
          teirType: 'wordpress',
          description: 'Ideal for individuals or small businesses, providing easy setup with pre-installed WordPress.',
          benefits: [
               'Page Builder Tools',
               'Free SSL Certificate',
               'Daily Backups',
               'Email Support',
               'Elementor Themes Templates',
               '1 Collaborator',
               'One-Time 15-Minute Kick-Off Consultation *'
          ]
     }, 
     {
          name: 'Growth Plan',
          tagline: 'Perfect for Growing Websites',
          monthlyCost: 45.00,
          monthlyCheckoutUrl: 'https://buy.stripe.com/28o8AtffiaLr8CY5km',
          annualCost: 432.00,
          annualCheckoutUrl: 'https://buy.stripe.com/aEU9ExaZ2bPv6uQbIL',
          teirType: 'wordpress',
          description: 'Tailored for growing websites needing more flexibility and features.',
          benefits: [
               'All Starter Plan Features',
               '5 Collaborators',
               'E-commerce',
               'Staging Environment',
               'Event Calendar',
               'Marketing Suite',
               'Premium Template Kits',
               'One-Time 30-Minute Kick-Off Consultation *'
          ]
     },
     {
          name: 'Enterprise Plan',
          tagline: 'Customized Solutions',
          monthlyCost: 155.00,
          monthlyCheckoutUrl: 'https://buy.stripe.com/8wM4kdebebPvdXi3cg',
          annualCost: 1500.00,
          annualCheckoutUrl: 'https://buy.stripe.com/28odUN1os5r75qMeUZ',
          teirType: 'wordpress',
          description: 'Best for large enterprises needing customized solutions with full access to developer tools.',
          benefits: [
               'All Premium Plan Features',
               'Unlimited Collaborators',
               'Dedicated Account Manager',
               'Migration Services',
               'Advanced Developer Tools',
               'One-Time 60-Minute Kick-Off Consultation *'
          ]
     }
];