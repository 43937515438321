import React from "react";
import { motion } from "framer-motion";



/**
 * Render Primary Logo
 * 
*/
export default function Logo() {
     return( 
          <React.Fragment>
               <h1 className="logoWordMark">
                    HostBot
               </h1>
               <div className="logoGraphicContainer">
                    <motion.div 
                         whileHover={{ scale: 1.3 }} 
                         whileTap={{ scale:0.8  }} 
                    ></motion.div>
               </div>
          </React.Fragment>
     )
}
