import React, { Component } from "react";
import RegisterDomain from "./stepper/RegisterDomain";
import RegisterWebHosting from "./stepper/RegisterWebHosting";
import RegisterEmailHosting from "./stepper/RegisterEmailHosting";
import RegisterBusinessProfile from "./stepper/RegisterBusinessProfile";
import RegisterConfirmation from "./stepper/RegisterConfirmation";



export class HostPackageStepper extends Component 
{
     state = {
          step: 1,
          teirPrice: this.props.priceSelection,
          monthlyCheckoutUrl: this.props.monthlyCheckoutUrl,
          annualCheckoutUrl: this.props.annualCheckoutUrl,
          firstName: "",
          lastName: "",
          email: "",
          domainNew: "", 
          webHostPackage: this.props.selectionPackage,
          registeredDomain: "", 
          emailServiceSetup: "", 
          numberOfEmails: "", 
          webhostServiceSetup: "", 
          webhostBillingSetup: "",
          contactPhone: ""
     };
     

     // Proceed to next step
     continues = (e) => {
          e.preventDefault();
          const { step } = this.state;
          this.setState({
               step: step + 1,
          });
          //console.log(this.state.teirPrice)
     };


     // Go back to prev step
     back = (e) => {
          e.preventDefault();
          const { step } = this.state;
          this.setState({
               step: step - 1,
          });
     };


     // Handle fields change
     handleChange = (input) => (e) => {
          this.setState({ [input]: e.target.value });
     };


     render() 
     {
          const { 
               step,
               teirPrice,
               domainNew, 
               registeredDomain, 
               emailServiceSetup, 
               numberOfEmails, 
               webHostPackage,
               webhostServiceSetup, 
               monthlyCheckoutUrl,
               annualCheckoutUrl,
               webhostBillingSetup,
               firstName, 
               lastName, 
               email,
               contactPhone 
          } = this.state;

          const values = { 
               domainNew, 
               teirPrice,
               registeredDomain, 
               emailServiceSetup, 
               numberOfEmails, 
               webHostPackage,
               webhostServiceSetup, 
               monthlyCheckoutUrl,
               annualCheckoutUrl,
               webhostBillingSetup,
               firstName, 
               lastName, 
               email, 
               contactPhone 
          };


          if(teirPrice === 1.00) {
               switch(step) {
                    case 1:
                         return(
                              <RegisterDomain
                                   nextStep={this.nextStep}
                                   handleChange={this.handleChange}
                                   values={values}
                                   continues={this.continues}
                              />
                         );
                    case 2:
                         return(
                              <RegisterEmailHosting
                                   nextStep={this.nextStep}
                                   prevStep={this.prevStep}
                                   handleChange={this.handleChange}
                                   values={values}
                                   back={this.back}
                                   continues={this.continues}
                              />
                         );
                    case 3:
                         return(
                              <RegisterBusinessProfile
                                   nextStep={this.nextStep}
                                   prevStep={this.prevStep}
                                   handleChange={this.handleChange}
                                   values={values}
                                   back={this.back}
                                   continues={this.continues}
                              />
                         );
                    case 4:
                    return(
                         <RegisterConfirmation
                              nextStep={this.nextStep}
                              prevStep={this.prevStep}
                              values={values}
                              back={this.back}
                         />
                    );
               }
          }
          else {
               switch(step) {
                    case 1:
                         return(
                              <RegisterDomain
                                   nextStep={this.nextStep}
                                   handleChange={this.handleChange}
                                   values={values}
                                   continues={this.continues}
                              />
                         );
                    case 2:
                         return(
                              <RegisterWebHosting
                                   nextStep={this.nextStep}
                                   prevStep={this.prevStep}
                                   handleChange={this.handleChange}
                                   values={values}
                                   back={this.back}
                                   continues={this.continues}
                              />
                         );
                    case 3:
                         return(
                              <RegisterEmailHosting
                                   nextStep={this.nextStep}
                                   prevStep={this.prevStep}
                                   handleChange={this.handleChange}
                                   values={values}
                                   back={this.back}
                                   continues={this.continues}
                              />
                         );
                    case 4:
                         return(
                              <RegisterBusinessProfile
                                   nextStep={this.nextStep}
                                   prevStep={this.prevStep}
                                   handleChange={this.handleChange}
                                   values={values}
                                   back={this.back}
                                   continues={this.continues}
                              />
                         );
                    case 5:
                    return(
                         <RegisterConfirmation
                              nextStep={this.nextStep}
                              prevStep={this.prevStep}
                              values={values}
                              back={this.back}
                         />
                    );
               }
          }

     }
}
export default HostPackageStepper;