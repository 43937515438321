import React, { useEffect } from 'react';
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';
import { Button, Container, Grid, Stack, Typography } from '@mui/material';
import DialogForm from "../components/dialog/DialogForm";
import BobbingSVG from "../components/BobbingSVG";
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import SendIcon from '@mui/icons-material/Send';



export default function SlideIntroduction(props) 
{
     const controls = useAnimation();
     const { ref, inView } =  useInView();

     
     useEffect(() => {
          if (inView) {
            controls.start('visible');
          }
          if (!inView) {
            controls.start('hidden');
          }
     }, [controls, inView]);


     const handlePricingClick = (e) => {
          props.fullPageSlide.moveSectionDown();
     }

     const handleQuestionsClick = (e) => {
          props.fullPageSlide.moveTo(3);
     }

     return(
          <React.Fragment>
               <Container sx={{padding: '1.5em' }}>
                    <Grid container>
                         <Grid item sx={{ maxWidth: '700px' }}>

                              <BobbingSVG />

                              <motion.div ref={ref} variants={animateContainer} initial="hidden" animate={controls}>
                                   <RocketLaunchIcon  color="primary" /><br/>
                                   <Typography variant="paragraph" component="paragraph" color="#FFFFFF" gutterBottom>
                                        <strong>DISCOVER HOSTBOT</strong>
                                   </Typography>
                                   <Typography variant="h1" component="h1" color="secondary" className="h1-shout" mt={1} gutterBottom>
                                        Crafting Reliable &amp; Secure<br/> 
                                        Web Hosting Solutions
                                   </Typography>
                                   <Typography variant="paragraph" component="paragraph" color="#FFFFFF">
                                        At HostBot, we offer scalable, fully managed websites designed to support the evolving demands of your business. Our customized hosting solutions ensure your website grows seamlessly alongside your business, providing unmatched performance and reliability.
                                   </Typography>

                                   <Stack spacing={2} mt={4} direction="row">
                                        <Button 
                                             variant="outlined" 
                                             onClick={ handlePricingClick }>
                                             Pricing
                                        </Button>

                                        <DialogForm  
                                             triggerVariant="text" 
                                             triggerColor="secondary" 
                                             triggerMessage="Need Hosting Support?" 
                                             triggerIcon={<SendIcon />}
                                        />
                                   </Stack>
                              </motion.div>
                              
                         </Grid>
                    </Grid>
               </Container>
          </React.Fragment>
     );
}


const animateContainer = {
     hidden: { opacity: 0 },
     visible: {
          opacity: 1,
          transition: {
               duration: 2,
               stiffness: 700,
               damping: 30,
               staggerChildren: 0.3
          }
     }
}