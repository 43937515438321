import React, { Component } from "react";
import { Button, Grid, Typography } from '@mui/material';
import { TextField, FormControl, InputAdornment } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';


export class RegisterBusinessProfile extends Component 
{
     render() 
     {
          const { values, handleChange, back, continues } = this.props;
          
          return (
               <React.Fragment>
                    <Grid container rowSpacing={3} columnSpacing={{ xs: 3 }}>
                         <Grid item xs={12}>
                              <Typography component="h3" variant="h6" color="#7758b0">
                                   A Little About You
                              </Typography>
                              <Typography variant="body1" gutterBottom>
                                   We can&apos;t forget about you! Please note your primary contact information 
                                   below and a member of HostBot will reach out to you to you to finalize your services.
                              </Typography>
                         </Grid>
                         <Grid item xs={6}>
                              <FormControl fullWidth>
                                   <TextField 
                                        id="firstName"
                                        name="firstName" 
                                        label="First Name" 
                                        variant="outlined" 
                                        size="small" 
                                        defaultValue={values.firstName}
                                        onChange={handleChange("firstName")}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                             endAdornment: (
                                                  <InputAdornment position="end">
                                                       <AccountCircleIcon />
                                                  </InputAdornment>
                                             ),
                                        }}
                                        required
                                   />
                              </FormControl>
                         </Grid>
                         <Grid item xs={6}>
                              <FormControl fullWidth>
                                   <TextField 
                                        id="lastName" 
                                        name="lastName"
                                        label="Last Name" 
                                        variant="outlined"
                                        size="small" 
                                        defaultValue={values.lastName}
                                        onChange={handleChange("lastName")}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                             endAdornment: (
                                                  <InputAdornment position="end">
                                                       <AccountCircleIcon />
                                                  </InputAdornment>
                                             ),
                                        }}
                                        required
                                   />
                              </FormControl>
                         </Grid>
                         <Grid item xs={6}>
                              <FormControl fullWidth>
                                   <TextField 
                                        id="email" 
                                        name="email"
                                        label="Email Address" 
                                        variant="outlined" 
                                        size="small" 
                                        defaultValue={values.email}
                                        onChange={handleChange("email")}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                             endAdornment: (
                                                  <InputAdornment position="end">
                                                       <MailOutlineIcon />
                                                  </InputAdornment>
                                             ),
                                        }}
                                        required
                                   />
                              </FormControl>
                         </Grid>
                         <Grid item xs={6}>
                              <FormControl fullWidth>
                                   <TextField 
                                        id="contactPhone" 
                                        name="contactPhone"
                                        label="Phone Number" 
                                        variant="outlined" 
                                        size="small" 
                                        defaultValue={values.contactPhone}
                                        onChange={handleChange("contactPhone")}
                                        InputLabelProps={{
                                             shrink: true,
                                        }}
                                        InputProps={{
                                             endAdornment: (
                                                  <InputAdornment position="end">
                                                       <PhoneIcon />
                                                  </InputAdornment>
                                             ),
                                        }}
                                   />
                              </FormControl>
                         </Grid>

                         <Grid item xs={6}>
                              <Button 
                                   onClick={back} 
                                   variant="contained" 
                                   color="teritary" 
                                   className="nextMenu">
                                   Back
                              </Button>
                         </Grid>
                         <Grid item xs={6}>
                              <Button 
                                   onClick={continues} 
                                   variant="contained" 
                                   color="teritary" 
                                   className="nextMenu floatRight">
                                   Continue
                              </Button>
                         </Grid>
                    </Grid>
               </React.Fragment>
          );
     }
}
export default RegisterBusinessProfile;