import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Button, Grid, Slide, Snackbar, Alert } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { TextField, FormControl, Select, InputLabel, MenuItem } from '@mui/material';



/*
 * Dialog Window Outlining Primary Contact Form
 *
*/
export default function DialogForm(props) 
{
     const [open, setOpen] = React.useState(false);
     const [openSnack, setOpenSnack] = React.useState(false);
     const [age, setAge] = React.useState(false);
     
     const handleChange = (event) => { setAge(event.target.value); }
     const handleContactDialog = () => { setOpen(true); }

     const handleClose = () => { setOpen(false); }
     const handleCloseSnack = (event, reason) => {
          if (reason === 'clickaway') {
               return;
          }
          setOpenSnack(false);
          setOpen(false);
     };


     /*
      * Contact Form handler
      * 
      * var   username
      * var   template id
      * var   api user account
      * array form data
      * 
     */
     const contactForm = useRef();
     const sendEmail   = (submissionEvent) => {

          submissionEvent.preventDefault();
          //setShowResults(false)
          //setAge(false);
          //setOpenSnack(true);

          emailjs.sendForm(
               'service_u2aaddx', 
               'template_pc6h76p', 
               contactForm.current, 
               '-N2oCE-_d5Ayarplr'
          ).then((result) => {
               setOpenSnack(true);
            }, (error) => {
               //setOpenSnack(true);
          });
          
     };

     return(
          <React.Fragment>
               <Button 
                    variant={ props.triggerVariant } 
                    startIcon={ props.triggerIcon }
                    onClick={handleContactDialog}>
                    { props.triggerMessage }
               </Button>
               <Dialog open={open} onClose={handleClose} TransitionComponent={Transition} keepMounted>
                    <DialogTitle color="teritary">
                         What Are Your Web Hosting Needs?
                    </DialogTitle>
                    
                    <form ref={contactForm} onSubmit={sendEmail} noValidate>
                         <DialogContent>
                              <DialogContentText>
                              <p>We offer a range of services, so let us know how we can help!</p>

                                   <Grid container rowSpacing={3} columnSpacing={{ xs: 3 }}>
                                        <Grid item xs={6}>
                                             <FormControl fullWidth>
                                                  <TextField 
                                                       id="contact-full-name"
                                                       name="contact-full-name" 
                                                       label="Full Name" 
                                                       variant="standard" 
                                                       fullWidth
                                                       required
                                                  />
                                             </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                             <FormControl fullWidth>
                                                  <TextField 
                                                       id="contact-email-address" 
                                                       name="contact-email-address"
                                                       label="Email Address" 
                                                       variant="standard"
                                                       fullWidth
                                                       required
                                                  />
                                             </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                             <FormControl fullWidth>
                                                  <TextField 
                                                       id="contact-website-url" 
                                                       name="contact-website-url"
                                                       label="Website Address" 
                                                       variant="standard" 
                                                       fullWidth
                                                  />
                                             </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                             <FormControl fullWidth>
                                                  <InputLabel id="contact-required-services">
                                                       Required Services
                                                  </InputLabel>
                                                  <Select
                                                       id="contact-required-services"
                                                       name="contact-required-services"
                                                       labelId="contact-required-services"
                                                       variant="standard" 
                                                       value={age}
                                                       label="Required Services"
                                                       onChange={handleChange}
                                                       >
                                                       <MenuItem value="New Hosting Account">New Hosting Account</MenuItem>
                                                       <MenuItem value="Website &amp; Email Support">Website &amp; Email Support</MenuItem>
                                                       <MenuItem value="Domain &amp; DNS Support">Domain &amp; DNS Support</MenuItem>
                                                       <MenuItem value=">Website Errors">Website Errors</MenuItem>
                                                  </Select>
                                             </FormControl>
                                        </Grid>
                                   </Grid>
                              </DialogContentText>
                         </DialogContent>
                         <DialogActions>
                              <Button variant="outlined" color="teritary" onClick={ handleClose }>Cancel</Button>
                              <Button type="submit" variant="contained" color="teritary" onClick={ sendEmail } >Submit Application</Button>
                         </DialogActions>
                    </form>

                    <Snackbar open={openSnack} autoHideDuration={3000} snackSeverity="success" onClose={handleCloseSnack}>
                         <Alert onClose={handleCloseSnack} severity="success">
                              Your request has been processed. We&apos;ll be in touch soon! 
                         </Alert>
                    </Snackbar>

               </Dialog>
          </React.Fragment>
     )
}



/*
* Slide Dialog Window into view
* 
* var   direction (up)
* 
*/
const Transition = React.forwardRef(function Transition(props, ref) 
{
     return <Slide direction="up" ref={ref} {...props} />;
});